import { createTheme } from "@mui/material";

const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: "#91fa91",
        dark: "#089808",
      },
      secondary: {
        main: "#9095F9",
        dark: "#081098",
      }
    },
  });
export default darkTheme;
